<template>

  <div class="stepbuilder-card" :ref="`card_${indexx+1}`" :id="`card_${indexx+1}`">




    <v-skeleton-loader
      :loading="false"
      transition="'scale-transition'"
      type="card"
    >
      <v-card color="white" class=" grey--text text--darken-1 current pa-2">
        <v-form :v-model="`valid.step_${indexx}`">
          <div class="my-2 d-flex align-center justify-space-between">

            <div class="d-flex align-center">

              <v-progress-circular
              :value="this.indexx < this.currentMarkerIndex ? 100: percent"
              :rotate="-90"
              color="secondary darken-1"
              class="ml-1 mr-2"
              >
              <h3 :class="{'secondary--text text--darken-2' : this.indexx < this.currentMarkerIndex, 'grey--text text--lighten-1' : this.indexx == this.currentMarkerIndex}">
                {{indexx+1}}</h3>
              </v-progress-circular>
              <!-- <v-icon class="play" color="white">fast_forward</v-icon> -->
              <v-text-field
              dense
              filled
              v-model="time"
              v-mask="'##:##:##'"
              :disabled="indexx==0"
              @keydown="countTime"
              @focus="selectAll"
              @blur="updateVideoTime"
              ref="stepTime"
              style="max-width:90px">
            </v-text-field>
          </div>

          <v-tooltip left  v-if="indexx > 0">
            <template v-slot:activator="{ on }" :disabled="$vuetify.breakpoint.smAndDown">
              <div class="d-flex align-center" v-on="on">
                <div class="mr-2" @click="step.autopause = !step.autopause">
                  <!-- <v-icon>pause_circle_outline</v-icon>  -->
                  Auto-pause
                </div>
                <v-switch inset v-if="indexx > 0" class="ma-0 pa-0 no-messages" v-model="step.autopause">
                </v-switch>
              </div>
            </template>
            <span >Step will pause video</span>
          </v-tooltip>

        </div>

        <v-text-field
        class="title mt-0 pt-1"
        v-model="step.title"
        :placeholder='`"Step ${indexx+1}" or your own title`'

        ></v-text-field>

        <wysiwyg :step="step" ></wysiwyg>

        <div class="d-flex align-center controls">
          <v-btn text class="pa-0 mr-4" color="primary" @click="clickStep(`#step_${indexx}`,step, $event)">
            <v-icon>play_arrow</v-icon> Play
          </v-btn>
          <v-btn text class="pa-0" color="secondary darken-1" @click="deleteStep(step)">
            <v-icon>delete</v-icon> Delete
          </v-btn>
        </div>

      </v-form>
      </v-card>
    </v-skeleton-loader>



  </div>
</template>

<script>
const wysiwyg = () => import( "@/components/shared/wysiwyg");
import {mask} from 'vue-the-mask'

export default {
  name: "stepbuilder-cards",
  props: ['step', 'stepView', 'published','skipIntro','timestamp','indexx', 'duration', 'timeIn', 'stepLength', 'nextTimestamp', 'currentMarkerIndex'],
  components:{wysiwyg},
  directives: {mask},
  data(){
    return{
      time: '00:00:00',
      percent: 0,
      lastInput: null,
    }
  },
  watch:{
    timeIn:function(newV,oldV){
      if(newV.timeupdate !== undefined && this.indexx == this.currentMarkerIndex){
        // process.env.NOD E_ENV === "development" ? console.log( 'is this runinig' ) : null;
        // $stepPercentLeft(currentIndex, stepLength, seconds, currentTimestamp, nextTimestamp, durationTotal)
        this.percent = this.$stepPercentLeft(this.indexx, this.stepLength, newV.timeupdate, this.step.timestamp, this.nextTimestamp, this.duration);
      }
    },
    timestamp:function(newV,oldV){
      // console.log(newV, oldV);
      this.oldTime = oldV;
      this.setTime(newV)
    },
    step: {
          handler: function (val, oldVal) {
            process.env.NODE_ENV === "development" ? console.log( 'val from video', val.timestamp, oldVal.timestamp ) : null;
            // Return the object that changed
              // this.stepList = val.steps;
          },
          deep: true
        },
  },
  methods: {
    clickStep(element, step, e){
      process.env.NODE_ENV === "development" ? console.log( element, step, e ) : null;
      let selection = window.getSelection()
      let data = {}
      data.step = {'step':step, 'timestamp':step.timestamp};
      data.origin = `card_step_${this.indexx+1}`

      this.$emit('onStepClicked', data)
    },
    deleteStep(step){
      // process.env.NODE_ENV === "development" ? console.log( 'deleteStep', step ) : null;
      // let data = JSON.parse(JSON.stringify(step))
      // let steps = this.stepList.slice()
      // let found = steps.findIndex(el => data.timestamp == el.timestamp);
      // let newArray = steps.splice(found, 1);
      // process.env.NODE_ENV === "development" ? console.log( 'newArray' ) : null;
      let data ={ 'step': step, 'index': this.indexx};
      this.$emit('deleteStep', data)
    },
    setTime(time){
      process.env.NODE_ENV === "development" ? console.log( 'set time', time ) : null;
      let stepTime = time ? time : this.step.timestamp
      this.time = this.$getTimeFormat(stepTime);
    },
    selectAll(e){
      process.env.NODE_ENV === "development" ? console.log( 'selected', e, this.step.timestamp ) : null;
      e.target.select()
      this.lastInput = this.step.timestamp
      // this.$refs.timer.select();
      // @focus.native="$event.target.select()"
    },
    countTime(e){
      if(e.key === 'ArrowUp'){
        e.preventDefault();
        if(e.key === 'ArrowUp' && e.shiftKey === true){
          let splitTime = this.time.slice();
          this.time = this.$moment(splitTime,'HH:mm:ss').add(10, 'seconds').format('HH:mm:ss')
        }
        else if(e.key === 'ArrowUp' && e.altKey === true){
          let splitTime = this.time.slice();
          this.time = this.$moment(splitTime,'HH:mm:ss').add(1, 'minutes').format('HH:mm:ss')
        }
        else{
          let splitTime = this.time.slice()
          this.time = this.$moment(splitTime,'HH:mm:ss').add(1, 'seconds').format('HH:mm:ss')
        }

      }
      else if(e.key === 'ArrowDown'){
        e.preventDefault();
        if(this.$moment.duration(this.time).asSeconds() > 0){
          if(e.key === 'ArrowDown' && e.shiftKey === true){
            let splitTime = this.time.slice();
            this.time = this.$moment(splitTime,'HH:mm:ss').subtract(10, 'seconds').format('HH:mm:ss')
          }
          else if(e.key === 'ArrowDown' && e.altKey === true){
            let splitTime = this.time.slice();
            this.time = this.$moment(splitTime,'HH:mm:ss').subtract(1, 'minutes').format('HH:mm:ss')
          }
          else{
            let splitTime = this.time.slice();
            this.time = this.$moment(splitTime,'HH:mm:ss').subtract(1, 'seconds').format('HH:mm:ss')
          }
        }
      }
      if(e.key === 'Enter'){
        // this.updateVideoTime();
        this.$refs.stepTime.blur();
      }
    },
    updateVideoTime(){
      this.errorMessage = null;
      let newTime = this.$moment.duration(this.time).asSeconds();
      let duration = this.$moment.duration(this.duration).asSeconds();
      if(this.lastInput != newTime){
        if(newTime <= duration){
          this.$emit('updateVideoTime', newTime)
          this.step.timestamp = newTime;
        }
        else{
          this.errorMessage = 'Whooops';
        }
      }
    },
  },
  mounted(){
    this.setTime(this.step.timestamp);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .stepbuilder-card{
    .v-card{
      padding: 0 10px 10px 10px;
      margin-bottom: 14px;
      opacity: .65;
      &.current{
        opacity: 1;
      }
      hr{
        opacity: .4;
      }
      .v-switch{
        padding: 0!important;
      }
      &.options{
        .title{
          font-size: 16px!important;
        }
      }
      .v-card{
        margin: 0;
      }
      .v-input{
        .v-input__slot{
          margin: 0;
        }
      }
      .v-text-field__details{
        display: none;
      }
      .no-messages{
        .v-messages{
          display: none!important;
        }
      }
    }
  }
</style>
